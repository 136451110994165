<template>
  <input style="width: 140px" ref="input" :disabled="disabled" type="tel" placeholder="vd: 28/04/1999" />
</template>

<script>
import Cleave from 'cleave.js'
import moment from 'moment'

export default {
  name: 'Cleave',
  props: ['disabled'],
  mounted() {
    new Cleave(this.$el, {
      date: true,
      datePattern: ['d', 'm', 'Y']
    })

    this.$el.oninput = e => {
      if (e.target.value.toString().length === 10 && moment(e.target.value, 'DD/MM/YYYY').isValid()) {
        this.$emit('error', null)
      } else {
        this.$emit('error', 'Vui lòng nhập đúng định dạng!')
      }
      this.$emit('change', e.target.value)
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    }
  }
}
</script>

<style scoped></style>
