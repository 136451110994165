<template>
  <a-spin :spinning="isLoading">
    <div class="container">
      <!-- <div class="pre-title" v-if="hasCustomerPhone !== ''">
                <div class="title-checkout mr-5">Khám phá khách hàng</div>
                <div style="font-size: 16px" class="mr-5">
                    Anh {{ customerInfo.fullName || '' }} - xxx.xxx.{{
                        hasCustomerPhone.slice(-3)
                    }}
                </div>
                <div class="subtitle">
                    Nếu có thông tin của bạn có thể trả lời để phục vụ khách tốt
                    hơn nữa nhé
                    <br />
                    Nếu chưa có thông tin, bạn có thể bỏ qua và hỏi vào lần sau
                </div>
            </div> -->
      <div class="title">Hiểu để phục vụ khách hàng</div>
      <div class="content">
        <div style="margin-left: 0px;margin-right: 0px;margin-top: 5px;">Nhập SĐT của khách</div>
        <a-input
          placeholder="SỐ ĐIỆN THOẠI"
          v-model="customerPhone"
          style="margin-left: 0;margin-right: 20px;margin-top: 5px;width:100%;"
        />
        <a-alert
          v-if="customerName != '' && customerName != null"
          :message="customerName"
          type="info"
          show-icon
          style="margin-left: 0;margin-right: 20px;margin-top: 5px;width:100%;"
        />
        <!-- style="left: 20px;right: 20px;margin-top: 5px;width: 200px;" -->
        <div style="margin-left: 0px;;margin-right: 0px;margin-top: 5px;">
          Nhập số điện thoại của khách sau đó chọn loại thông tin và ấn
          <span style="font-weight: bold;">"Xác nhận"</span> để tiến hành thêm thông tin khách hàng
        </div>
        <div style="margin-left: 0px;;margin-right: 0px;margin-top: 5px;">Chọn trường thông tin</div>
        <div style="margin-left: 0px;margin-right: 0px;margin-top: 5px;">
          <a-radio-group v-model="groupQuestionValue" @change="onChangeGroupQuestion">
            <a-radio :style="radioStyle" :value="7">
              KH feedback
            </a-radio>
            <a-radio :style="radioStyle" :value="8">
              Call center
            </a-radio>
            <!-- <a-radio :style="radioStyle" :value="9">
              Feedback khi khách gọi đến
            </a-radio>
            <a-radio :style="radioStyle" :value="10">
              Trường hợp khác
            </a-radio> -->
          </a-radio-group>
        </div>
        <!-- <div class="confirm-button" @click="fetchQuestion" :class="{ active: isActiveFetchQuestion }">
          XÁC NHẬN
        </div> -->
        <a-button
          style=" background: #ffcc33;font-size: 16px;line-height: 22px;"
          @click="fetchQuestion"
          :disabled="!isActiveFetchQuestion"
          block
          >XÁC NHẬN</a-button
        >
      </div>
      <div class="content">
        <div class="question" v-for="(question, index) in dataQuestion" :key="question.questionId">
          <div class="title-question">
            {{ `${index + 1}. ${question.content}` }}
          </div>
          <!-- type 1 -->
          <!-- <div
                        v-if="
                            !question.isMultichoice &&
                                question.answer.length < 5
                        "
                        class="content-question"
                    > -->
          <div v-if="!question.isMultichoice" class="content-question">
            <a-radio-group style="width: 100%" @change="event => handleChangeRadio(question.questionId, event)">
              <div v-for="answer in question.answer" :key="answer.id">
                <a-radio
                  :style="radioStyle"
                  :value="JSON.stringify(answer)"
                  :class="
                    answer.type == TYPE_TEXT || answer.type == TYPE_NUMBER || answer.type == TYPE_DATE
                      ? 'radio-button'
                      : answer.type == TYPE_LOCATION
                      ? 'address'
                      : ''
                  "
                  v-if="answer.type !== TYPE_LOCATION"
                >
                  {{ answer.content }}

                  <!-- type answer text -->
                  <a-input
                    :ref="`${question.questionId}_${answer.id}`"
                    v-if="answer.type == TYPE_TEXT"
                    :disabled="checkDisableInput(question.questionId, answer.id)"
                    :value="getValueInput(question.questionId, answer.id)"
                    @change="event => handleChangeInput(question.questionId, answer.id, event)"
                  />

                  <!-- type answer number -->
                  <a-input
                    :id="`${question.questionId}_${answer.id}`"
                    :ref="`${question.questionId}_${answer.id}`"
                    v-if="answer.type == TYPE_NUMBER"
                    :disabled="checkDisableInput(question.questionId, answer.id)"
                    :value="getValueInput(question.questionId, answer.id)"
                    @change="event => handleChangeInput(question.questionId, answer.id, event)"
                    type="number"
                  />

                  <cleave
                    v-if="answer.type == TYPE_DATE"
                    :disabled="checkDisableInput(question.questionId, answer.id)"
                    @error="value => onAnswerValidateError(question.questionId, answer.id, value)"
                    :ref="`${question.questionId}_${answer.id}`"
                    style="padding: 2px 5px; width: 140px; margin-left: 15px"
                    @change="
                      dateString =>
                        handleChangeInput(question.questionId, answer.id, {
                          target: {
                            value: dateString
                          }
                        })
                    "
                  >
                  </cleave>
                </a-radio>

                <a-radio :style="radioStyle" :value="JSON.stringify(answer)" :class="'address'" v-else>
                  {{ answer.content }}

                  <!-- type answer address  -->
                  <a-select
                    class="search-address"
                    dropdownClassName="search-address-detail"
                    show-search
                    placeholder="Nhập địa chỉ"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="debounceSearch"
                    @change="
                      value =>
                        handleChangeInput(question.questionId, answer.id, {
                          target: {
                            value
                          }
                        })
                    "
                    @focus="handleClearAddress"
                    :value="getValueInput(question.questionId, answer.id)"
                    :ref="`${question.questionId}_${answer.id}`"
                    v-if="answer.type == TYPE_LOCATION"
                    :disabled="checkDisableInput(question.questionId, answer.id)"
                  >
                    <a-select-option
                      v-for="address in listAddressSearch"
                      :key="`${address.longitude}-${address.latitude}`"
                      :value="JSON.stringify(address)"
                    >
                      {{ address.address }}
                    </a-select-option>
                  </a-select>
                </a-radio>
                <span
                  v-if="getAnswerValidateError(question.questionId, answer.id)"
                  style="font-size: 12px; color: red; display: block; margin-left: 25px;"
                >
                  {{ getAnswerValidateError(question.questionId, answer.id) }}
                </span>
              </div>
            </a-radio-group>
          </div>

          <!-- type 3 -->
          <div class="content-question" v-if="question.isMultichoice">
            <a-checkbox
              :class="`${answer.type == TYPE_LOCATION ? 'search-address-checkbox' : ''} checkbox`"
              v-for="answer in question.answer"
              :key="answer.id"
              @change="event => handleChangeCheckBox(question.questionId, answer, event)"
            >
              {{ answer.content }}
              <a-input
                :ref="`${question.questionId}_${answer.id}`"
                style="margin-left: 3px"
                v-if="answer.type == TYPE_TEXT"
                :disabled="checkDisableInput(question.questionId, answer.id)"
                :value="getValueInput(question.questionId, answer.id)"
                @change="event => handleChangeInput(question.questionId, answer.id, event)"
              />

              <!-- type number -->
              <a-input
                :ref="`${question.questionId}_${answer.id}`"
                style="margin-left: 3px"
                v-if="answer.type == TYPE_NUMBER"
                :disabled="checkDisableInput(question.questionId, answer.id)"
                :value="getValueInput(question.questionId, answer.id)"
                @change="event => handleChangeInput(question.questionId, answer.id, event)"
                type="number"
              />

              <!-- type date -->
              <cleave
                v-if="answer.type == TYPE_DATE"
                :disabled="checkDisableInput(question.questionId, answer.id)"
                :ref="`${question.questionId}_${answer.id}`"
                style="padding: 2px 5px; width: 140px; margin-left: 15px"
                @change="
                  dateString =>
                    handleChangeInput(question.questionId, answer.id, {
                      target: {
                        value: dateString
                      }
                    })
                "
              >
              </cleave>

              <!-- type answer address  -->
              <a-select
                class="search-address"
                dropdownClassName="search-address-detail"
                show-search
                placeholder="Nhập địa chỉ"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @search="debounceSearch"
                @change="
                  value =>
                    handleChangeInput(question.questionId, answer.id, {
                      target: {
                        value
                      }
                    })
                "
                :value="getValueInput(question.questionId, answer.id)"
                @focus="handleClearAddress"
                :ref="`${question.questionId}_${answer.id}`"
                v-if="answer.type == TYPE_LOCATION"
                :disabled="checkDisableInput(question.questionId, answer.id)"
              >
                <a-select-option
                  v-for="address in listAddressSearch"
                  :key="`${address.longitude}-${address.latitude}`"
                  :value="JSON.stringify(address)"
                >
                  {{ address.address }}
                </a-select-option>
              </a-select>
              <span
                v-if="getAnswerValidateError(question.questionId, answer.id)"
                style="font-size: 12px; color: red; display: block; margin-left: 25px;"
              >
                {{ getAnswerValidateError(question.questionId, answer.id) }}
              </span>
            </a-checkbox>
          </div>
        </div>

        <div class="footer" v-if="showFooter">
          <div class="group-button">
            <div class="button btn-exit" @click="handleResetForm">
              Reset dữ liệu
            </div>
            <div class="button" @click="handleSendAnswer">
              Gửi trả lời
            </div>
          </div>
          <div class="content-process">
            <a-progress :percent="100" />
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { RepositoryFactory } from '@/api/RepositoryFactory'
const CustomerInsightRepository = RepositoryFactory.get('customerInsightRepository')
import { mapGetters } from 'vuex'
// import {
//     fetchQuestion,
//     postAnswer,
//     fetchCustomer,
//     searchAddress,
// } from '@/repository/customerInsightRepository';
//import moment from 'moment';
//import 'moment/locale/vi';
import Cleave from '@/components/DatePicker/Cleave'

import { uuid } from 'vue-uuid'
// import trackingConst from '../../config/tracking';
// import {postTracking} from '../../repository/trackingRepository';

const TYPE_TEXT = 2
const TYPE_DATE = 3
const TYPE_LOCATION = 4
const TYPE_NUMBER = 5

export function removeUnicodeChar(str) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str = str.replace(/Đ/g, 'D')
  // Combining Diacritical Marks
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // huyền, sắc, hỏi, ngã, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, '') // mũ â (ê), mũ ă, mũ ơ (ư)
  return str
}

export default {
  components: {
    Cleave
  },
  data() {
    return {
      error: {},
      dateOfBirth: undefined,
      value: 1,
      radioStyle: {
        display: 'flex',
        lineHeight: '25px',
        marginBottom: '8px'
      },
      isActiveFetchQuestion: true,
      isLoading: false,
      dataQuestion: [],
      listAnswer: [],
      TYPE_TEXT,
      TYPE_DATE,
      TYPE_LOCATION,
      TYPE_NUMBER,
      customerInfo: {},
      hasCustomerPhone: '',
      customerPhone: '',
      customerName: '',
      dateFormat: 'DD/MM/YYYY',
      //moment,
      listAddressSearch: [],
      debounce: null,
      tokenKey: null,
      groupQuestionValue: null,
      showFooter: false,
      customerId: null,
      sendAnswerSuccess: false
    }
  },

  computed: {
    ...mapGetters(['user'])
  },
  created() {
    //this.fetchQuestion();
    //this.fetchCustomer();
    const { customerPhone } = this.$route.query
    if (customerPhone) this.hasCustomerPhone = customerPhone
    this.tokenKey = uuid.v1()
  },

  methods: {
    handleResetForm() {
      this.isActiveFetchQuestion = true
      this.customerPhone = ''
      this.customerName = ''
      this.groupQuestionValue = null
      this.dataQuestion = []
      this.listAnswer = []
      this.customerId = null
      this.showFooter = false
      this.sendAnswerSuccess = false
    },
    onChangeGroupQuestion(e) {
      //reset lai thong tin cau hoi
      this.isActiveFetchQuestion = true
      this.dataQuestion = []
      this.listAnswer = []
      this.showFooter = false
      this.sendAnswerSuccess = false
      console.log('radio checked', e.target.value)
    },

    async fetchQuestion() {
      this.isLoading = true

      if (!this.isActiveFetchQuestion) {
        return
      }

      console.log('validate', this.customerPhone, this.groupQuestionValue)

      if (this.customerPhone.length < 10 || this.customerPhone == '') {
        this.$message.error('Vui lòng nhập đủ 10 số của số điện thoại')
        this.isLoading = false
        return
      }

      if (this.groupQuestionValue == null) {
        this.$message.error('Vui lòng chọn trường thông tin')
        this.isLoading = false
        return
      }
      // const {
      //         os = 1,
      //         customerId = 1,
      //         accessToken = '',
      //         staffId = 0,
      //         billId = 0,
      //         token = ''
      //     } = this.$route.query;

      // const params = {
      //     customerId,
      //     os,
      //     accessToken: accessToken || token,
      //     staffId: parseInt(staffId) || 0,
      //     billId: parseInt(billId) || 0,
      // };

      // var trackingInfo ={
      //         listQuestion,
      //         customerId,
      //         os,
      //         staffId: parseInt(staffId) || 0,
      //         billId: parseInt(billId) || 0,
      //     }
      try {
        const response = await CustomerInsightRepository.fetchQuestion(this.customerPhone, this.groupQuestionValue)
        //debugger
        const { data } = response
        this.dataQuestion = data || []
        this.customerId = response.customerId
        if (response.customerId == 0) {
          //kh chua ton tai tren he thong
          this.$message.error('KHÁCH MỚI CHƯA CÓ TRÊN HỆ THỐNG')
          return
        }
        //
        this.customerName =
          response.customerName != null ? 'Tên khách hàng: ' + response.customerName : response.customerName
        console.log('dataQuestion', this.dataQuestion, data.data, data, this.customerId)
        // this.dataQuestion = testData;
        // this.dataQuestion = (fakeResponse && fakeResponse.data) || [];
        this.listAnswer = this.dataQuestion.map(question => ({
          questionId: question.questionId,
          listAnswer: []
        }))
        this.isActiveFetchQuestion = false
        this.showFooter = true

        //tracking thong tin question lay dc
        // var listQuestion =this.dataQuestion.map((question) => ({
        //     questionId: question.questionId
        // }));
        // trackingInfo.listQuestion = listQuestion;

        // this.postTrackingEvent(trackingConst.Events.GET_QUESTION_SUCCESS, {trackingInfo});
      } catch (error) {
        console.log(error)
        //debugger;
        // this.postTrackingEvent(trackingConst.Events.GET_QUESTION_FAIL,
        //         {   message : error.message,
        //             url : error.config.url,
        //             params : trackingInfo
        //         });
        this.$message.error('Lấy danh sách câu hỏi thất bại, thử lại sau.')
      } finally {
        this.isLoading = false
      }
    },

    // async postTrackingEvent(eventId,extendData) {
    //     try {
    //         const dataTracking = {
    //             pageId: trackingConst.Pages.CUSTOMER_QUESTION_PAGE,
    //             screenId: trackingConst.Screens.CUSTOMER_QUESTION_SCREEN,
    //             eventId,
    //             tokenKey: this.tokenKey,
    //             source: null,
    //             utmSource: null,
    //             extendData
    //         };

    //         await postTracking(dataTracking);
    //     } catch (e) {
    //         console.log(e.message);
    //     }
    // },

    onAnswerValidateError(questionId, anwserId, message) {
      this.error[`${questionId}_${anwserId}`] = message
    },

    getAnswerValidateError(questionId, anwserId) {
      if (this.error[`${questionId}_${anwserId}`]) return this.error[`${questionId}_${anwserId}`]
      return null
    },

    // async fetchCustomer() {
    //     try {
    //         const {
    //             customerPhone = '',
    //             accessToken = '',
    //             token = '',
    //         } = this.$route.query;
    //         if (customerPhone) {
    //             const params = {
    //                 phone: customerPhone,
    //                 accessToken: accessToken || token,
    //             };
    //             const response = await fetchCustomer(params);
    //             const { data } = response;
    //             this.customerInfo = data || {};
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // },

    // async fetchAddress(keyword) {
    //     try {
    //         const { accessToken = '', token = '' } = this.$route.query;
    //         const accessTokenCp = accessToken || token;
    //         if (keyword) {
    //             const response = await searchAddress(keyword, accessTokenCp);
    //             this.listAddressSearch = response.data || [];
    //         } else this.listAddressSearch = [];
    //     } catch (error) {
    //         this.listAddressSearch = [];
    //     }
    // },

    handleClearAddress() {
      if (this.listAddressSearch.length > 0) {
        this.listAddressSearch = []
      }
    },

    debounceSearch(value) {
      clearTimeout(this.debounce)
      if (this.listAddressSearch.length > 0) {
        this.listAddressSearch = []
      }
      this.debounce = setTimeout(() => {
        this.fetchAddress(removeUnicodeChar(value))
      }, 500)
    },

    checkDisableInput(questionId, answerId) {
      const question = this.listAnswer.find(ans => ans.questionId == questionId)
      if (question && question.listAnswer.length > 0) {
        const answer = question.listAnswer.find(ans => ans.id == answerId)
        return !answer
      } else return true
    },

    getValueInput(questionId, answerId) {
      const question = this.listAnswer.find(ques => ques.questionId == questionId)
      if (question) {
        const answer = question.listAnswer.find(ans => ans.id == answerId)
        return (answer && answer.content) || ''
      }
      return ''
    },

    getValueDateInput(questionId, answerId) {
      const question = this.listAnswer.find(ques => ques.questionId == questionId)
      let value = ''
      if (question) {
        const answer = question.listAnswer.find(ans => ans.id == answerId)
        value = (answer && answer.content) || ''
      }
      if (value) {
        return value
      } else return ''
    },

    handleChangeInput(questionId, answerId, event) {
      const question = this.listAnswer.find(ques => ques.questionId == questionId)
      const answer = question.listAnswer.find(ans => ans.id == answerId)
      answer.content = event.target.value
      this.$forceUpdate()
    },

    handleChangeRadio(questionId, event) {
      const { value } = event.target
      const answer = JSON.parse(value)
      this.listAnswer = this.listAnswer.map(item => {
        if (item.questionId != questionId) return item
        else if (
          answer.type === TYPE_TEXT ||
          answer.type === TYPE_DATE ||
          answer.type === TYPE_LOCATION ||
          answer.type === TYPE_NUMBER
        ) {
          this.$nextTick(function() {
            this.$refs[`${questionId}_${answer.id}`][0].focus()
            // this.$refs[`${questionId}_${answer.id}`][0].autoFocus = true;
            // console.log(this.$refs[`${questionId}_${answer.id}`][0])
          })
          return {
            questionId,
            listAnswer: [
              {
                id: answer.id,
                content: ''
              }
            ]
          }
        } else
          return {
            questionId,
            listAnswer: [
              {
                id: answer.id
              }
            ]
          }
      })
    },

    handleChangeCheckBox(questionId, answer, event) {
      const isCheck = event.target.checked
      if (isCheck) {
        this.listAnswer = this.listAnswer.map(item => {
          if (item.questionId != questionId) return item
          else if (
            answer.type === TYPE_TEXT ||
            answer.type === TYPE_DATE ||
            answer.type === TYPE_LOCATION ||
            answer.type === TYPE_NUMBER
          ) {
            // setTimeout(() => {
            //     // eslint-disable-next-line no-unused-vars
            //     const element = document
            //         .getElementById(`${answer.id}`)
            //         .focus();
            // }, 100);
            this.$nextTick(function() {
              this.$refs[`${questionId}_${answer.id}`][0].focus()
            })

            item.listAnswer.push({
              id: answer.id,
              content: ''
            })
          } else
            item.listAnswer.push({
              id: answer.id
            })
          return item
        })
      } else {
        this.listAnswer = this.listAnswer.map(item => {
          if (item.questionId != questionId) return item
          else {
            item.listAnswer = item.listAnswer.filter(item => item.id !== answer.id)
            return item
          }
        })
      }
    },

    handleChangeSelect(questionId, value) {
      this.listAnswer = this.listAnswer.map(item => {
        if (item.questionId != questionId) return item
        else
          return {
            questionId,
            listAnswer: [
              {
                id: value
              }
            ]
          }
      })
    },

    formatAnswer() {
      let listAnswerWithOutEmpty = this.listAnswer.filter(answer => answer.listAnswer.length > 0)
      if (listAnswerWithOutEmpty.length > 0) {
        listAnswerWithOutEmpty = listAnswerWithOutEmpty.map(answer => {
          if (answer.listAnswer.length > 0) {
            answer.listAnswer = answer.listAnswer.map(ans => {
              if (ans.content && ans.content.includes('longitude')) {
                const address = JSON.parse(ans.content)
                return {
                  ...ans,
                  content: `${address.longitude}-${address.latitude}`
                }
              }
              return ans
            })
          }
          return answer
        })
      }
      return listAnswerWithOutEmpty
    },

    async handleSendAnswer() {
      this.isLoading = true
      const {
        os = this.groupQuestionValue
        // customerId = 1,
        // accessToken = '',
        // token = '',
        //staffId = 0,
        // billId = 0,
        // returnUrl,
        // returnUrlName
      } = this.$route.query
      const body = {
        customerId: this.customerId,
        os: os,
        staffId: parseInt(this.user.userId) || 0,
        billId: 0,
        answer: this.formatAnswer()
      }

      //  var trackingInfo = {
      //     customerId: parseInt(customerId),
      //     os: parseInt(os),
      //     staffId: parseInt(staffId) || 0,
      //     billId: parseInt(billId) || 0,
      //     answer: this.formatAnswer(),
      // }
      try {
        if (this.dataQuestion.length == 0) {
          //ko co cau hoi
          //this.$message.error('Không có câu hỏi')
          return
        }

        if (this.sendAnswerSuccess) {
          this.$message.error('Đã gửi câu trả lời rồi, không được gửi lại')
          return
        }
        //neu ko chon cau tra loi thi van call api
        // if (params.answer.length == 0) {
        //     this.$router.push({
        //         path: '/success',
        //         query: { os },
        //     });
        //     return;
        // }
        // eslint-disable-next-line no-unused-vars
        const response = await CustomerInsightRepository.postAnswer(body)

        this.$message.success('Gửi câu trả lời thành công')
        this.sendAnswerSuccess = true
        //tracking tra loi thanh cong

        // this.postTrackingEvent(trackingConst.Events.SEND_ANSWER_SUCCESS, {trackingInfo});
        //
        // this.$router.push({
        //   path: '/success',
        //   query: { os, returnUrl, returnUrlName }
        // })
      } catch (error) {
        console.log(error)
        //  this.postTrackingEvent(trackingConst.Events.SEND_ANSWER_FAIL,
        //         {   message : error.message,
        //             url : error.config.url,
        //             params : trackingInfo
        //         });
        this.$message.error('Gửi câu trả lời thất bại')
        this.sendAnswerSuccess = false
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
* {
  font-family: Be;
  color: #000000;
}

input {
  margin-left: 5px;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #e8e8e8;
}

input:focus {
  box-shadow: none;
  border-bottom: 1px solid #e8e8e8;
}

.container {
  min-height: 100vh;
  width: 100%;
}

.ant-spin-spinning {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
}

.pre-title {
  padding: 12px;
}

.mr-5 {
  margin-bottom: 5px;
}

.title-checkout {
  text-transform: uppercase;
  color: black;
  font-weight: bold;
}

.pre-title .subtitle {
  font-size: 12px;
  color: red;
}

.title {
  background: #000000;
  color: white;
  font-family: Be-bold;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  padding: 12px;
}

.content {
  padding: 0px 20px;
}

.question {
  padding-bottom: 12px;
  border-bottom: 1px solid #ececec;
  padding-top: 12px;
}

.title-question {
  font-size: 16px;
  line-height: 22px;
}
.content-question {
  padding: 5px 15px;
}

.content-question-checkbox {
  margin-top: 10px;
  width: 100%;
  margin-bottom: 15px;
}

.checkbox {
  width: 100%;
  margin-bottom: 10px;
  min-height: 24px;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px;
}

.group-button {
  display: flex;
  justify-content: space-around;
}

.confirm-button {
  text-align: center;
  background: #ffcc33;
  border-radius: 5px;
  padding: 6px 20px;
  font-size: 16px;
  line-height: 22px;
  width: auto;
  cursor: pointer;
  left: 20px;
  right: 20px;
  margin-top: 5px;
}

.button {
  text-align: center;
  background: #ffcc33;
  border-radius: 5px;
  padding: 6px 20px;
  font-size: 16px;
  line-height: 22px;
  width: 150px;
  cursor: pointer;
}

.btn-exit {
  background-color: lightgrey;
}

.button:hover {
  opacity: 0.7;
}

.footer {
  margin-top: 50px;
  margin-bottom: 20px;
}

.content-process {
  margin-top: 20px;
}
</style>

<style lang="scss">
.search-address-detail {
  .ant-select-dropdown-menu-item,
  li {
    overflow: auto !important;
    text-overflow: unset !important;
    white-space: unset !important;
    border-bottom: 1px lightgray solid;
  }
}

.ant-radio-group .ant-radio-wrapper {
  .ant-radio {
    display: flex;
    width: auto;
    margin-top: 7px;
    height: fit-content;
    .ant-radio-inner {
      width: 16px;
      height: 16px;
    }
  }
  span {
    display: inline-block;
    width: -webkit-fill-available;
    white-space: normal !important;
  }
}

.ant-progress-success-bg,
.ant-progress-bg {
  background-color: #ffcc33;
}

.ant-select-dropdown-menu-item {
  color: #000000;
}

.ant-progress-status-success .ant-progress-bg {
  background-color: #ffcc33;
}

.ant-progress-status-success .ant-progress-text {
  color: #ffcc33;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: unset;
}

.ant-checkbox-wrapper {
  display: flex;
  align-items: center;
}
.ant-checkbox-wrapper > span:last-child {
  display: flex;
  align-items: center;
  width: 100%;
}

.radio-button {
  display: flex !important;
  align-items: center;
  margin-bottom: 5px;
}

.radio-button > span:last-child {
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 0px;
}

.address > span:last-child {
  display: flex !important;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.address > span:first-child {
  margin-top: 8px;
}

.address {
  display: flex !important;
  align-items: flex-start;
  height: auto !important;
}

.select-date {
  margin-left: 5px;
  flex: 1;
  input {
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #e8e8e8;
  }
}

.search-address-checkbox {
  .ant-select-selection {
    width: 100%;
  }

  .ant-select {
    width: 100%;
    margin-top: 5px;
  }
}

.search-address-checkbox > span:last-child {
  flex-direction: column;
  align-items: flex-start;
}

.search-address {
  flex: 1;
  margin-left: 5px;
  display: flex;
  .ant-select-open.ant-select-selection {
    box-shadow: none;
  }

  .ant-select-selection {
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #e8e8e8;
    width: 100%;
  }

  .ant-select-selection:active {
    box-shadow: none;
    outline: none;
  }
}
</style>
